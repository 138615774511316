export const brand = {
    "NAME": "SZ",
    "FAVICON": "sz",
    "HELP": "https://gmtech-cz.atlassian.net/l/cp/aWA1UwKK",
    "LOGIN": {
      "LOGO_PATH": "sz.png",
      "SSO": "",
      "APP_URL": "https://gmtech.cz"
    },
    "PS": {
      "TITLE": "MAJA",
      "ICON_PATH": "maja",
      "URL_BASE_PATH": "maja",
      "WELCOME_EMAIL": {
        "SUBJECT": "Přístupové údaje do aplikace MAJA",
        "BODY": "Dobrý den,\n\nv tomto emailu naleznete Vaše nové přístupové údaje do aplikace MAJA.\n\nwebová adresa: [https://app.gmtech.cz|https://app.gmtech.cz]\npřihlašovací jméno: [login]\njednorázové heslo pro první přihlášení: XXXXXX\n\nSoučástí prvního přihlášení je požadavek na změnu hesla. Z důvodů ochrany Vašeho přístupu a z obecných požadavků na tvar hesla doporučujeme ve Vašem novém heslu používat kombinace malých a velkých písmen, číslic a znaků.\n\nObecné informace o aplikaci MAJA, doporučené postupy a návody lze najít na naší [stránce podpory MAJA|https://gmtech-cz.atlassian.net/wiki/x/vQB4].\n\nSoučástí naší aplikace je internetový systém HelpDesk sloužící pro naši vzájemnou komunikaci při práci ve vašem projektu. Pokud budete potřebovat poradit s aplikací, upravit šablonu dokumentu, či jakkoliv jinak změnit data v MAJA, obracejte se na nás pomocí tohoto komunikačního kanálu. Pro přihlášení do Helpdesku Vám přijde samostatný automaticky generovaný email. Helpdesk spustíte přímo v naší aplikaci, kliknutím na ikonu otazníku v pravém horním rohu. Kdybyste měli problém s aktivací, připravili jsme jednoduchý postup, jak [helpdesk aktivovat|https://gmtech-cz.atlassian.net/wiki/x/1gB4]."
      }
    },
    "VISIBILITY": {
      "maja": true,
      "mpv": false,
      "common.navbar.big": true,
      "common.navbar.small": false,
      "sv.unit": false,
      "settings.modules": false
    },
    "TRANSLATE": {
      "ps.project": "Akce",
      "ps.projects": "Akce",
      "ps.projectGenitive": "Akce",
      "ps.projectAccusative": "Akci",
      "ps.projectsAccusative": "Akcí",
      "ps.projectList": "Seznam akcí",
      "ps.projectList.total": "Celkový počet případů",
      "ps.projectList.done": "Celkový počet vypořádaných případů",
      "ps.projectList.donePercentage": "Celkový stav vypořádání v %",
      "ps.projectList.expropriationTotal": "Vyvlastnění počet případů celkem",
      "ps.projectName": "Název akce",
      "ps.projectManager": "Manažer akce",
      "ps.propertyActivityProcessor": "Zpracovatel MP činností",
      "ps.projectInfo": "Karta akce",
      "ps.unit": "Zařazení akce do úseku",
      "ps.investor": "Organizační jednotka",
      "ps.projectSettings": "Nastavení akce",
      "ps.infrastructure": "Infrastruktura",
      "ps.projectStartDate": "Datum spuštění projektu",
      "ps.projectState": "Stav akce",
      "ps.projectState.CREATED": "Připravovaná akce",
      "ps.projectState.STARTED": "Aktivní akce",
      "ps.projectState.STARTED_COMPLETE": "Aktivní akce úplná",
      "ps.projectState.STARTED_PARTIAL": "Aktivní akce částečná",
      "ps.projectState.PAUSED": "Pozastavená akce",
      "ps.projectState.FINISHED": "Uzavřená akce",
      "ps.case.id": "Id případu",
      "login.sending": "Přihlašování...",
      "login.loggedOut": "Byli jste odhlášeni",
      "ps.easementCoefficient": "Koeficient",
      "dashboard.name": "Dashboard",
      "dashboard.header": "Projekty",
      "ps.investorCommonName": "SŽ",
      "sv.templatesOrganizationalUnit": "Organizační jednotka",
      "sv.section": "Úsek",
      "sv.section.invest": "Úsek výstavby",
      "sv.section.additional": "Úsek provozní"
    }
  }
;
